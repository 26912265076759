$smu-bg-body: #B5B8BB;
$smu-bg-salmon: #ffbc99;
$smu-bg-orange-light: #FEEFD4;
$smu-bg-orange: #ffd88d;
$smu-bg-orange-dark: #F2A91D;
$smu-bg-ligth: #9a9fa5;
$smu-bg-ligther: #efefef;
$smu-bg-darker: #1a1d1f;
$smu-bg-blue-light: #b1e5fc;
$smu-bg-green-light: #B5E4CA;

$smu-text-ligth: #6f767e;
$smu-text-dark: #33383f;
$smu-text-darker: #1a1d1f;
$smu-text-red: #E31C19;

$smu-color-red: #E31C19;