@import "./variables/colors";
@import "./utilities/placeholders";

// UTILS
.c-pointer {
  cursor: pointer;
}

// ADMIN LAYOUT
.admin-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;

  .top-bg {
    height: 256px;
    width: 100%;
    background-color: #3f4a59;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

// SCROLL
.scroll-container {
  overflow-y: auto;
  max-height: 100%;
}

// SMU CONTAINER
.smu-container {
  margin-left: 0;
}
@media (min-width: 992px) {
  .smu-container {
    margin-left: 100px;
  }
}

// BADGE
.smu-badge {
  min-width: 17px;
  max-width: 17px;
  height: 35px;
  border-radius: 4px;
}

// BACKGROUNDS
.smu-bg-body {
  background-color: #f4f4f4;
}

@media (min-width: 992px) {
  .smu-bg-body {
    background-color: #adb0b3;
  }
}

// CARD
.smu-card,
.smu-card-secondary {
  background-color: #fff;
  border: none !important;
  border-radius: 8px !important;
  padding-top: 0.5rem;
  height: 100%;

  .card-body {
    display: flex;
    flex-direction: column;
  }

  .card-header {
    min-height: 56px !important;
    box-sizing: border-box;
    background-color: transparent;
  }
}

.smu-card-secondary {
  background-color: #f4f4f4 !important;
  padding: 0;
}

// BADGE
.smu-badge {
  min-width: 17px;
  max-width: 17px;
  height: 35px;
}
 
// TABLE
.smu-table_ {
  &.red-table {
    .rdt_TableHeadRow {
      background-color: $smu-color-red;
  
      .rdt_TableCol {
        color: #fff;
      }
    }
  }

  &.purple-table {
    .rdt_TableHeadRow {
      background-color: #8c96f6;
  
      .rdt_TableCol {
        color: #fff;
      }
    }
  }

  &.striped {
    .rdt_TableBody {
      .rdt_TableRow:nth-child(odd) {
        background-color: #f9f9f9 !important;
      }
    }
  }

  .gZWIKX {
    min-height: 40px !important;
  }

  .rdt_TableHeadRow {
    background-color: #cfd6dc;

    .rdt_TableCol {
      color: #4e5d6a;
    }
  }
}

.smu-table {
  .order-up,
  .order-down {
    border: none;
    outline: none;
    background-color: red;
    width: 10px;
    height: 10px;
  }

  &.borderless {
    td {
      border-bottom: none;
    }
  }

  &.striped {
    tbody {
      tr:nth-child(odd) {
        td {
          background-color: #f9f9f9 !important;
        }
      }
    }
  }

  &.bordered {
    border-collapse: collapse;

    td {
      border: 1px solid #ebebeb !important;
    }
  }

  .cell {
    display: flex;
    align-items: center;
    //height: 35px;
  }

  thead {
    background-color: #cfd6dc;

    th {
      font-weight: 400;
      font-size: 16px;
      color: #4e5d6a;
    }
  }

  tbody {
    border: none !important;

    td {
      border-bottom: 1px solid #ebebeb;
    }

    .tr-selected,
    tr:hover {
      background-color: #f9f9f9 !important;
    }
  }
}

.table-ornament {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 4rem;
  right: 0;
  z-index: 3;
  height: 50%;
  width: 70%;
}

.smu-pagination {
  width: 100%;
  display: flex;

  .page-item {
    margin: 0 5px;

    &.active {
      .page-link {
        color: #fff;
        background-color: #e31c19;
        border-color: #e31c19;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .page-link {
      background-color: #f4f8fa;
      height: 36px;
      width: 36px;
      border-radius: 4px;
      border-color: #f4f8fa;
      color: #748a9d;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }
}

// FROM
.smu-form {
  .form-label {
    margin-bottom: 0.75rem;
    font-size: 18px;
    color: $smu-text-dark;
  }

  input.form-control,
  select.form-select {
    height: 48px;
    border: 2px solid #dedfe0;
    border-radius: 12px;
    color: #9a9fa5;
  }

  .form-control {
    background-color: #f4f4f4;
    border-color: #f4f4f4 !important;
  }
}

.form-check-input:checked {
  background-color: $smu-color-red !important;
  border-color: $smu-color-red !important;
}

// SELECT
.indicators-select {
  border: 2px solid $smu-color-red !important;
  background-color: #fff !important;
}

// BUTTON
.btn-swith {
  @extend %smu-btn;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8c96f6;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: none;
  outline: none;
}

.btn-order-sss,
.btn-order-doh {
  @extend %smu-btn;
  background-color: $smu-color-red;
  border: 2px solid $smu-color-red;
  color: #fff;
}

.btn-order-instock {
  @extend %smu-btn;
  background-color: transparent;
  border: 2px solid #2a85ff;
  color: #2a85ff;
}

.btn-order-nsg {
  @extend %smu-btn;
  background-color: transparent;
  border: 2px solid #b5e4ca;
  color: #b5e4ca;
}

.btn-budget {
  @extend %smu-btn;
  background-color: transparent;
  border: 2px solid $smu-color-red;
  color: $smu-color-red;
}

.btn-calendar,
.btn-see-graph,
.btn-back {
  @extend %smu-btn;
  background-color: transparent;
  border: 2px solid $smu-color-red;
  color: $smu-color-red;
}

.smu-btn-to-up {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  right: 1rem;
  bottom: 1rem;
  position: absolute;
  z-index: 5;
  border: none;
  outline: none;
  background-color: $smu-color-red;
}

.smu-button-table,
.smu-generic-button {
  &:active {
    transform: scale(0.98);
  }
}

.smu-button-table {
  background-color: #f4f8fa;
  border: 2px solid #f4f8fa;
  border-radius: 8px;
  width: 36px;
  height: 36px;
}

.smu-generic-button {
  border-radius: 12px;
  color: #e31c19;
  border: 2px solid #e31c19;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: transparent;
}

.smu-button-table-options {
  @extend %flex-center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;

  &:active {
    transform: scale(0.9);
  }
}

// TABS
.smu-tabs,
.smu-tabs-graph {
  padding: 0 1rem;
  border: 0;
  box-sizing: border-box;

  .nav-item {
    margin: 0 2px;
  }

  .nav-link {
    border: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #9a9fa5;
    background-color: #fff;

    &.active {
      color: $smu-color-red;
    }
  }
}

.smu-tabs-graph {
  padding: 0;

  .nav-item {
    margin: 0;
  }

  .nav-link {
    background-color: #e9e9e9;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    color: #6f767e !important;
  }
}

// CALENDAR
.smu-calendar {
  position: relative;
  display: flex;

  &__popover {
    padding: 1rem;
    box-sizing: content-box;
    min-width: min-content;

    .popover-arrow {
      display: none;
    }

    .react-calendar {
      border: none;
      width: 100%;

      .react-calendar__navigation {
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        box-sizing: content-box;
        border-bottom: 2px solid #f6f6f6;
        margin-bottom: 1.5rem;

        .react-calendar__navigation__prev2-button {
          margin-left: auto !important;
        }

        .react-calendar__navigation__next2-button {
          margin-right: auto !important;
        }

        .react-calendar__navigation__label {
          max-width: fit-content;
          white-space: nowrap;
        }

        .react-calendar__navigation__label,
        .react-calendar__navigation__arrow {
          background-color: transparent;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          margin: 0 2.5px;

          &:active {
            border: 2px solid $smu-color-red;
          }

          &:hover {
            background-color: transparent;
          }
        }

        .react-calendar__navigation__arrow {
          width: 35px;
          font-size: 24px;
          color: $smu-color-red;
          font-weight: 600;
        }
      }

      .react-calendar__month-view__weekdays__weekday {
        font-weight: 100;
        text-transform: capitalize;

        abbr {
          text-decoration: none !important;
        }
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        visibility: hidden !important;
      }

      .react-calendar__tile--now {
        color: #fff;
        background-color: $smu-color-red;

        &:hover {
          color: #fff;
          background-color: $smu-color-red;
          font-weight: 600;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }

      .react-calendar__tile--active {
        background-color: transparent !important;
        box-shadow: inset 0 0 0 2px $smu-color-red;
        color: $smu-color-red;
        font-weight: 600;
      }
    }

    .body {
      position: relative;

      .calendar-placeholder {
        position: absolute;
        top: -1rem;
        left: -1rem;
        height: calc(100% + 2rem);
        width: calc(100% + 2rem);
        background-color: rgba(0, 0, 0, 0.11);
        z-index: 5;
        border-radius: 0.3rem 0.3rem 0 0;
        display: flex;
      }
    }

    .footer {
      display: flex;
      border-top: 2px solid #f6f6f6;
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      height: 32px;
      box-sizing: content-box;

      .close-calendar {
        @extend %smu-btn;
        border-radius: 0;
        background-color: transparent;
        border: 2px solid $smu-color-red;
        color: $smu-color-red;
      }
    }
  }
}
