.smu-select {
  height: 40px;
  border: 0;
  background-color: #f4f4f4;
  border-radius: 12px;
  color: #999ea4;

  background-image: url("../images/cheveron-down.png");
  background-repeat: no-repeat;
  background-size: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline: 0 !important;
  }
}