@import "./variables/colors";

.smu-loading {
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #fff;
  padding: .5rem 1.25rem;
  border-radius: .5rem;
  border: 2px solid #ebebeb;
  color: $smu-text-ligth;
  font-size: .9rem;

  img {
    width: 28px;
    height: 28px;
  }
}
