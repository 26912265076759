.smu-btn-close {
  height: 40px;
  width: 40px;
  position: absolute;
  top: -16px;
  right: -12px;
  background-color: #fff;
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &:active {
    transform: scale(0.95);
  }
}