.smu-bg-salmon {
  background-color: $smu-bg-salmon;
}
.smu-bg-blue-light {
  background-color: $smu-bg-blue-light;
}
.smu-bg-orange {
  background-color: $smu-bg-orange;
}
.smu-bg-red {
  background-color: $smu-color-red;
}
.smu-bg-green-light {
  background-color: $smu-bg-green-light;
}



.smu-text-ligth {
  color: $smu-text-ligth;
}
.smu-text-dark {
  color: $smu-text-dark;
}
.smu-text-darker {
  color: $smu-text-darker;
}
.smu-text-red {
  color: $smu-color-red;
}