@import "~bootstrap/scss/bootstrap";

@import "assets/styles/variables/colors";

@import "assets/styles/utilities/mixins";
@import "assets/styles/utilities/colorsClass";

@import "assets/styles/generals";

#root {
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  display: flex;
}