.smu-search {
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 12px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    height: 40px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
