%base-input {
  border: none;
  outline: none;
  height: 48px;
  width: 100%;
}

.logo-v1 {
  width: 144px;
  height: 113px;
}

.graphic-icon {
  width: 48px;
  height: 48px;
}

.text-graphic {
  font-size: 35px;
  color: #000;
}

.footer {
  display: flex;
  height: 48px;

  .text-footer {
    font-size: 14px;
    color: #707070;
  }
}

.title {
  font-size: 15px;
  color: #707070;
}

.control {
  background-color: #f4f4f4;
  border-radius: 12px;
  height: 48px;
  display: flex;
  cursor: text;
  margin-bottom: 1rem;

  img {
    margin: auto 0.85rem;
  }

  input {
    @extend %base-input;
    background-color: transparent;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    &::placeholder {
      color: #9a9fa5;
    }
  }
}

.btn-signin,
.signin-loading {
  @extend %base-input;
  background-color: #e31c19;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
}

.btn-signin {
  &:active {
    transform: scale(0.99);
  }
}

.signin-loading {
  user-select: none;
  
  img {
    width: 28px;
    height: 28px;
  }
}

.divider-horizontal,
.divider-vertical {
  background-color: #efefef;
}

.divider-vertical {
  width: 2px;
  height: 290px;
  margin: auto auto 0 auto;
}
.divider-horizontal {
  background-color: #efefef;
  width: 100%;
  height: 2px;
  margin: auto auto 0 auto;
}

.recaptcha {
  margin: 0 auto;
  width: 80%;
  padding-bottom: 10px;
}