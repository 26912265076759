%smu-btn {
  font-weight: 500;
  height: 32px;
  font-size: 14px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  outline: none;

  &:active {
    transform: scale(0.97);
  }
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
