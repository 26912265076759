@import "./utilities/placeholders";

.smu-profile-photo-editor {
  margin: auto;
  position: relative;
}

.editor-control {
  position: absolute;
  bottom: 0;
  left: calc(100% - 35px);
  display: flex;
  align-items: center;

  .btn-edit {
    @extend %flex-center;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    background-color: #f4f8fa;
    border: 0;
    outline: 0;
    border-radius: 8px;
    margin-right: 10px;

    &:active {
      transform: scale(0.97);
    }
  }

  label {
    cursor: pointer;
    user-select: none;
    font-size: 13px;
    white-space: nowrap;
  }
}
