@import "variables/colors";

.col-37 {
  width: 37%;
}
.col-25 {
  width: 25%;
}

.divider {
  height: 48px;
  width: 1px;
  background-color: $smu-bg-ligther;
  margin: auto 1rem;
}

.btn-toggle {
  height: 48px;
  min-width: 48px;
  max-width: 48px;
  width: 48px;
  background-color: $smu-bg-ligther;
  border-radius: 12px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    transform: scale(.95);
  }
}

// CALENDAR
.smu-reload {
  position: relative;
  display: flex;
  margin-left: 10px;
}

.profile {
  cursor: pointer;

  &__name {
    margin: auto 0;
    padding: 0 1rem;
    color: #6f767e;
  }
}

.profile-dropdown {
  .dropdown-menu {
    border-radius: 1rem;
    border: 0;
    background-color: #f8f8f8;
    padding: 0.75rem 0;
  }

  .dropdown-item {
    border-bottom: 1px dashed #d9dee5;
    border-radius: 0;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    color: #33383f !important;

    &:active {
      background-color: transparent;
    }
  }
}

.topbar-admin {
  padding: 4rem 0 2.5rem 0;
  z-index: 1;
  height: 60px;
  box-sizing: content-box;

  .tagline {
    font-size: 16px;
    color: #748a9d;
  }

  .profile {
    &__name {
      color: #fff;
    }
  }
}
