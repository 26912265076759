@import "variables/colors";

.sidebar-fade {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .5;
  z-index: 9;
}

.sidebar {
  position: absolute;
  top: 0;
  left: -100px;
  transition: all 0.3s;
  background-color: #fff;
  min-width: 100px;
  max-width: 100px;
  min-height: 100%;
  max-height: 100%;
  z-index: 10;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);

  &.open {
    left: 0;
    min-width: 80%;
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .sidebar {
    left: 0;
    
    &:hover {
      min-width: 300px;
      max-width: 300px;
    }
  }
}

.header {
  min-height: 80px;
  max-height: 80px;
  //width: 100px;

  img {
    margin: auto 10px;
    width: 48px;
    height: 48px;
  }

  .title {
    overflow: hidden;
    white-space: nowrap;
  }
}

.menu {
  list-style: none;

  &-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #32373e;
    font-weight: 400;

    &:active {
      transform: scale(0.97);
    }

    &.active,
    &:hover {
      .icon {
        background-color: $smu-bg-ligther;
      }

      .text {
        color: $smu-text-red;
      }
    }

    .icon {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 10px;
    }

    .text {
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .divider {
    height: 2px;
    background-color: $smu-bg-ligther;
  }
}
