@import "./variables/colors";

.smu-modal {
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .modal-content {
    border-radius: 8px !important;
    border: 0 !important;
  }
}
